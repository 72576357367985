<template>
  <div class="container is-widescreen">
    <div class="columns is-centered">
      <div class="column is-8">
        <div :class="{ 'activity-navbar': true, 'anonymous': isAnonymousProject }">
          <button
            v-if="!isAnonymousProject"
            class="left"
            @click="goToProjects"
          >
            Anterior
          </button>
          <button
            class="right"
            :class="{ disabled: !enableButtonNext }"
            @click="goToScreening"
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'TermsAndConditionsNavbar',
  computed: {
    ...mapGetters('participationProject', ['isAnonymousProject']),
    ...mapState('participationProject', ['projectData']),
    ...mapState('participationParticipant', ['participantData']),
    enableButtonNext() {
      // Allow advancing to next step if there are not terms required or they have been accepted
      return ((!this.projectData.relevant_tnc_required || this.participantData.relevantTncAcceptedOn)
        && (!this.projectData.project_tnc_required || this.participantData.projectTncAcceptedOn))
    }
  },
  methods: {
    goToProjects() {
      this.$router.push({ name: 'proyectos' })
    },
    goToScreening() {
      this.$router.push({
        name: 'participation-activity',
        params: { identifier: this.projectData.identifier }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-navbar {
  height: 60px;
  background: $white;
  border: 1px solid $gray-ultralight;
  box-shadow: 0px 2px 4px rgba(81, 107, 152, 0.16);
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  &.anonymous {
    justify-content: flex-end;
  }

  &.hidden {
    display: none;
  }

  button {
    width: 130px;
    height: 35px;
    border-radius: 17.5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    outline: none;

    &.left {
      background: $white;
      border: 1px solid $button-border-gray;
      color: $black;
      margin-left: 14px;

      &:hover {
        border: 1px solid $gray;
        transition: all 0.2s ease-in-out;
      }
    }

    &.right {
      color: $white;
      background: $blue;
      box-shadow: 3px 3px 10px -1px rgba(11, 27, 102, 0.3);
      border: none;
      margin-right: 14px;

      &:hover {
        background-color: $blue-hover;
        transition: all 0.2s ease-in-out;
      }
    }

    &.disabled {
      pointer-events: none;
      background-color: $button-disabled;
    }
  }
}
</style>
