<template>
  <participation-view-content-container :current-stage="currentStage">
    <terms-and-conditions-acceptance />
    <terms-and-conditions-navigation-navbar class="float-bottom-navbar" />
  </participation-view-content-container>
</template>

<script>
import ParticipationViewContentContainer from '@/components/participation/ParticipationViewContentContainer.vue'
import TermsAndConditionsAcceptance from '@/components/participation/terms-and-conditions/TermsAndConditionsAcceptance.vue'
import TermsAndConditionsNavigationNavbar from '@/components/participation/terms-and-conditions/TermsAndConditionsNavigationNavbar.vue'
import participationStages from '@/components/participation/participationStages'

export default {
  name: 'Terminos',
  components: {
    ParticipationViewContentContainer,
    TermsAndConditionsAcceptance,
    TermsAndConditionsNavigationNavbar
  },
  created() {
    this.currentStage = participationStages.termsAndConditions
  }
}
</script>
