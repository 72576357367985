import { render, staticRenderFns } from "./TermsAndConditionsNavigationNavbar.vue?vue&type=template&id=4f86acf5&scoped=true&"
import script from "./TermsAndConditionsNavigationNavbar.vue?vue&type=script&lang=js&"
export * from "./TermsAndConditionsNavigationNavbar.vue?vue&type=script&lang=js&"
import style0 from "./TermsAndConditionsNavigationNavbar.vue?vue&type=style&index=0&id=4f86acf5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f86acf5",
  null
  
)

export default component.exports