<template>
  <div class="container is-widescreen">
    <div class="columns is-centered">
      <div class="column is-8">
        <div class="header-bar">
          <div class="section-title">
            <h1>Aceptá los Términos y Condiciones</h1>
          </div>
        </div>
        <div class="ilustration">
          <img src="../../../assets/terms/terms-and-conditions.svg">
        </div>
        <div class="description">
          Antes de comenzar, necesitamos que leas detenidamente y aceptes los
          Términos y Condiciones de Relevant y del investigador.
        </div>
        <div class="terms">
          <div class="term-box">
            <div class="text">
              <span>Términos de Servicio</span> de la plataforma
            </div>

            <router-link
              class="btn-read-more"
              :to="{ name: 'participation-terms-and-conditions-details', params: { type: 'relevant' } }"
              :class="relevantTermsButtonProperties.class"
            >
              {{ relevantTermsButtonProperties.text }}
            </router-link>
          </div>
          <div
            v-if="projectData.project_tnc_required"
            class="term-box"
          >
            <div class="text">
              <span>Términos y Condiciones</span> del investigador
            </div>

            <router-link
              class="btn-read-more"
              :to="{ name: 'participation-terms-and-conditions-details', params: { type: 'investigador' } }"
              :class="projectTermsButtonProperties.class"
            >
              {{ projectTermsButtonProperties.text }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TermsAndConditions',
  computed: {
    ...mapState('participationProject', ['projectData']),
    ...mapState('participationParticipant', ['participantData']),

    relevantTermsButtonProperties() {
      if (this.participantData.relevantTncAcceptedOn) {
        return { text: 'Aceptado', class: 'accepted' }
      }

      if (this.participantData.relevantTncRejectedOn) {
        return { text: 'Rechazado', class: 'rejected' }
      }

      return { text: 'Leer', class: 'clickable' }
    },

    projectTermsButtonProperties() {
      if (this.participantData.projectTncAcceptedOn) {
        return { text: 'Aceptado', class: 'accepted' }
      }

      if (this.participantData.projectTncRejectedOn) {
        return { text: 'Rechazado', class: 'rejected' }
      }

      return { text: 'Leer', class: 'clickable' }
    }
  },
  mounted() {
    this.scrollToTop()
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .header-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;

    .section-title {
      h1 {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }

  .ilustration {
    img {
      max-width: 271px;
    }
  }

  .description {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    margin-top: 34px;
  }

  .terms {
    text-align: left;
    margin-top: 26px;

    .term-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 68px;
      border: 1px solid #e1e1e1;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.0250765);
      border-radius: 5px;
      padding: 24px 21px;
      margin-bottom: 12px;

      .text {
        font-size: 16px;
        line-height: 20px;
        color: $black;

        span {
          color: $blue;
          font-weight: 600;
        }
      }

      .btn-read-more {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 120px;
        height: 35px;
        background-color: $blue;
        color: $white;
        border: none;
        border-radius: 20px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: $blue-hover;
          transition: all 0.2s ease-in-out;
        }

        &.accepted {
          background: $green url("../../../assets/terms/term-button-check.svg") 16px 10px no-repeat;
          padding-left: 30px;
          pointer-events: none;
        }

        &.rejected {
          background: $red url("../../../assets/terms/term-button-check.svg") 16px 10px no-repeat;
          padding-left: 30px;
          pointer-events: none;
        }
      }
    }
  }
}
</style>
